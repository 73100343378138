<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline"
    color="transparent"
    :clipped-left="rtl"
    :clipped-right="!rtl"
    height="70"
    flat
  >
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="changeDrawer" />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title class="font-weight-light text-h5" v-text="$t(getTitle)" />

    <v-spacer />

    <v-menu v-if="loggedIn" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar color="primary">
        <v-btn text v-bind="attrs" v-on="on">
          <span class="white--text text-h5">{{ getInitials }}</span>            
        </v-btn>
          </v-avatar>
        
      </template>
      <v-list>
        <v-list-item>
          <h6 class="text-h6">{{ getUsername }}</h6>
        </v-list-item>        
        <v-list-item>
          <v-btn text v-on:click="logout"> {{ $t('logout') }} </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn v-else text v-on:click="gotoLogin">
      <h6 class="text-h6">{{ $t('login') }}</h6>
    </v-btn>
  </v-app-bar>
</template>

<script>

export default {
  name: "DefaultBar",

  components: {
    DefaultDrawerToggle: () =>
      import(
        /* webpackChunkName: "default-drawer-toggle" */
        "./widgets/DrawerToggle"
      ),
  },

  data() {
    return {
    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.loggedIn;
    },
    drawer() {
      return this.$store.state.drawer;
    },
    drawerImage() {
      return this.$store.state.drawerImage;
    },
    rtl() {
      return this.$store.state.rtl;
    },
    getInitials() {
      return this.$store.getters.getInitials;
    },
    getUsername() {
      return this.$store.getters.getUsername;
    },
    getEmail() {
      return this.$store.getters.getEmail;
    },
    getTitle() {
      return this.$store.state.pageTitle;
    },
  },

  methods: {
    logout() {
      this.$store.commit("changeToken", "");
      this.$store.commit("changeLoggedIn", false);
      this.$router.push("/login");
    },
    gotoLogin() {      
      this.$router.push("/login");
    },
    changeDrawer() {
      this.$store.commit("changeDrawer", !this.drawer);
    },
    changeDrawerIamge() {
      this.$store.commit("changeDrawerImage", !this.drawer);
    },
    changeLanguage() {
      if(this.$i18n.locale === 'en') {
        this.$i18n.locale = 'de';
      }
      else {
        this.$i18n.locale = 'en';
      }
    }
  },
};
</script>
